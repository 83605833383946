import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { unmountComponentAtNode } from "react-dom";
import {base} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import Confetti from 'react-confetti';
import classNames from 'classnames';
import { getDistance } from 'geolib';
import Loading from '../utils/Loading';
import appBackgroundImage from '../../styles/images/background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import sampleAppTopImage from '../../styles/images/top_icon.png'
import sampleCardImage from '../../styles/images/card_image.svg'
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CustomCSS.css';
import { validateEmail } from '../utils/HelpfulFunction';

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userEmail: localStorage.getItem('userEmail') || sessionStorage.getItem('tempToken') || false,
          active: false,
          width: 0,
          height: 0,
          numberOfMoves: 0,
          alreadyUpdate: "",
          currentGameId: null,
          currentGameTime: false,
          currentResponseArray: [],
          startConfetti: false,
          rotationStarted: false,
          optedIntoEmail: {},
          tierMessaging: [],
          matches: [],
          modal: false,
          userHighScore: null,
          guess1 : null,
          guess2 : null,
          locationChecking: true,
          loading: true
        };
        this.logout = this.logout.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggle = this.toggle.bind(this);
        this.winnerToggle = this.winnerToggle.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element');
      const target = document.getElementById('react-confirm-alert');
      if(target){
        unmountComponentAtNode(target);
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg');
      if(svg){
        svg.parentNode.removeChild(svg);
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    componentDidMount(){
      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          if(typeof key === "string"){
            this.logUserActivity(key);
            this.removeReactAlert();
            this.restartClicked();
            this.setState({
              currentGameId:key,
              startConfetti: false,
              numberOfMoves: 0
            })
          }
        }
      });

      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });

      const userEmail = this.state.userEmail;
      let base64EncodedEmail = btoa(userEmail);
      if(!validateEmail(userEmail)){
        base64EncodedEmail = userEmail;
      }

      this.matchesRef = base.listenTo('currentGame/matches', {
        context: this,
        asArray: true,
        then(matchesData){
          if(matchesData){
            this.shuffleMatches(matchesData)
          }
        }
      })

      this.activeRef = base.listenTo('currentGame/active', {
        context: this,
        then(dataActive){
          if(this.props.variables && this.props.variables.collectDistance && dataActive){
            this.checkUsersLocation();
          } else {
            this.setState({
              active: dataActive,
              locationChecking: false,
              loading:false
            })
          }
        }
      })

      this.userHighScoreRef = base.bindToState(`userAnswers/${base64EncodedEmail}/score`, {
        context: this,
        state: 'userHighScore'
      })

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.scrollTo(0,0)
    }

    changeMilesToMeters(milesToConvert){
      return milesToConvert*1609.344;
    }

    getLocation(){
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject("Geolocation is not supported by your browser. Please change browsers to play!");
        } else {
          const toCheckLatitude = this.props.variables.latitude || 51.525;
          const toCheckLongitude = this.props.variables.longitude || 7.4575;
          navigator.geolocation.getCurrentPosition(
              function(position) {
                resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                  latitude: toCheckLatitude,
                  longitude: toCheckLongitude,
                }))
              },
              (err) => {
                if(err.message === "User denied Geolocation"){
                  reject("Position could not be determined because the browser does not have permission.  Please go to your browsers settings to allow it access to your location");
                } else {
                  console.log(err.message);
                  reject("An unknown error occurred, check your internet connection and try again");
                }
              }
          );
        }
      })
    }

    getLocationPermission(){
      const locationErrorTitle = "Location Error";
      this.getLocation().then(distance_meters => {
        localStorage.setItem('locationPermissions', "true");
        const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
        if(distance_meters <= allowed_distance){
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false,
            active: true
          });
        } else if(this.props.variables.canPlay) {
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false,
            active: true,
            locationCheckFailed: true
          });
        } else {
          this.setState({
            modal:false,
            loading:false
          });
          confirmAlert({
            title: locationErrorTitle,
            variables: this.props.variables,
            message: "Too far from game area to participate!",
            buttons: [
              {
                label: 'Retry',
                onClick: () => {
                  this.checkUsersLocation()
                }
              }
            ]
          });
        }
      }, error => {
        console.log(error);
        this.setState({
          modal:false,
          loading:false
        });
        localStorage.setItem('locationPermissions', "false");
        if(typeof error != "string"){
          error = error.message
        }
        confirmAlert({
          title: locationErrorTitle,
          variables: this.props.variables,
          message: error,
          buttons: [
            {
              label: 'Retry',
              onClick: () => {
                this.checkUsersLocation()
              }
            }
          ]
        });
      })
    }

    checkUsersLocation(){
      const variables = this.props.variables;
      const locationPermissions = localStorage.getItem('locationPermissions');
      if(!this.props.variables.collectDistance) {
        this.setState({locationChecking: false})
      } else if(locationPermissions === "false" || !locationPermissions){
        const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
        const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
        confirmAlert({
          title: locationPermissionsHeader,
          variables: variables,
          message: locationPermissionsBody,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                this.setState({
                  loading: true,
                });
                this.getLocationPermission()
              }
            }
          ],
        })
      } else {
        this.setState({
          loading: true,
        });
        this.getLocationPermission()
      }
    }

    shuffleMatches(matchesData){
      if(matchesData){
        const doubleMatches = []
        let count = matchesData.length;
        for(let i=0; i < matchesData.length; i++){
          const firstVersion = matchesData[i];
          firstVersion['flipped'] = false;
          firstVersion['discovered'] = false;
          const secondVersion = Object.assign({}, firstVersion);
          firstVersion['localId'] = i;
          secondVersion['localId'] = count+i;
          doubleMatches.push(firstVersion);
          doubleMatches.push(secondVersion);
        }
        this.shuffle(doubleMatches)
        this.setState({
          matches: doubleMatches
        })
      }
    }

    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
        [array[i], array[j]] = [array[j], array[i]];
      }
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    winnerToggle() {
      this.setState({
        winnerModal: !this.state.winnerModal,
        startConfetti: false,
        downloadedFile: null
      });
    }

    toggleRules() {
      this.setState({
        modalRules: !this.state.modalRules,
      });
    }

    componentWillUnmount() {
      base.removeBinding(this.currentGameKeyRef);
      base.removeBinding(this.tenantRulesRef);
      base.removeBinding(this.matchesRef);
      base.removeBinding(this.activeRef);
      base.removeBinding(this.userHighScoreRef);
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
      });
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    logout(){
      localStorage.removeItem('userEmail');
      localStorage.removeItem('verifiedAge');
      localStorage.removeItem('birthday');
      localStorage.removeItem('locationPermissions');
      this.setState({
        userEmail:false
      })
    }

    fireWinningMessage(numberOfMoves){
      const userEmail = this.state.userEmail;
      let base64EncodedEmail = userEmail;
      if(validateEmail(userEmail)){
        base64EncodedEmail = btoa(userEmail)
      }
      const variables = this.props.variables;
      this.setState({
        startConfetti: true
      })

      if(!this.state.userHighScore || typeof this.state.userHighScore === "object" || this.state.userHighScore === 0 || this.state.userHighScore > numberOfMoves){
        const userResponse = {};
        userResponse['timeStamp'] = Date.now();
        userResponse['numberOfMoves'] = numberOfMoves;
        userResponse['uid'] = base64EncodedEmail;
        userResponse['failedLocationCheck'] = this.state.locationCheckFailed;
        const vm = this;
        // fetch(`http://localhost:5001/memorygamedev/us-central1/checkIfGetsPrize`, {
        fetch(`https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/checkIfGetsPrize`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userResponse),
        }).then(res => {
          vm.setState({
            loading: false
          })
          Promise.resolve(res.json()).then(function (value) {
            console.log(value)
            if (value.login_failure || !value.result) {
              confirmAlert({
                variables: variables,
                title: "Error",
                message: "Something went wrong",
                buttons: [
                  {
                    label: 'OK'
                  }
                ]
              })
            } else {
              let messageHeader;
              let messageBody;
              if (value.result === "no_prize_sent") {
                messageHeader = variables.losingHeader;
                messageBody = variables.losingMessage;
              } else if(value.result === "error_committed") {
                messageHeader = "There Was An Error";
                messageBody = "Something went wrong, check your internet connection and try again!";
                confirmAlert({
                  variables: variables,
                  title: messageHeader,
                  message: messageBody,
                  buttons: [
                    {
                      label: 'Retry',
                      onClick: () => {
                        vm.fireWinningMessage(numberOfMoves)
                      }
                    }
                  ]
                })
                return;
              } else if (value.result === "main_reward_sent") {
                messageHeader = variables.winningHeader;
                messageBody = variables.winningMessage;
              }
              if (!messageHeader) {
                messageHeader = "You Win!";
              }
              if (!messageBody) {
                messageBody = "Congratulations";
              }
              confirmAlert({
                variables: variables,
                title: messageHeader,
                message: messageBody,
                buttons: [
                  {
                    label: 'OK',
                    onClick: () => {
                      vm.setState({startConfetti: false})
                    }
                  }
                ]
              })
            }
          })
        }).catch(err =>{
          console.log(err)
          vm.setState({
            loading: false
          })
          const messageHeader = variables.losingHeader || "You Solved The Puzzle!";
          const messageBody = variables.losingMessage || "Nice job but can you do better? Try again and this time do it in less moves";
          confirmAlert({
            variables: variables,
            title: messageHeader,
            message: messageBody,
            buttons: [
              {
                label: 'OK',
                onClick: () => vm.setState({startConfetti: false})
              }
            ]
          })
        })
      } else {
        const messageHeader = variables.losingHeader || "You Solved The Puzzle!";
        const messageBody = variables.losingMessage || "Nice job but can you do better? Try again and this time do it in less moves";
        confirmAlert({
          variables: variables,
          title: messageHeader,
          message: messageBody,
          buttons: [
            {
              label: 'OK',
              onClick: () => this.setState({startConfetti: false})
            }
          ]
        })
      }
      if(this.state.currentGameId){
        this.logUserActivity(this.state.currentGameId);
      }
    }

    logUserActivity(currentGame){
      const vm = this;
      if(currentGame){
        const gameid = currentGame;
        const userEmailToBase64 = btoa(this.state.userEmail);
        base.post('userGameHistory/'+userEmailToBase64+`/${gameid}` , {
          data:gameid,
          then(err){
            if(!err){
              vm.setState({
                alreadyUpdate: gameid
              })
              console.log("user game logged!")
            }
          }
        })
      }
    }

    renderHoldingScreen(){
      const tenantVariables = this.props.variables || {};
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const playingTextHeader = tenantVariables.playingTextHeader || "No Game Up";
      const playingTextBody = tenantVariables.playingTextBody || "Come back later to play";

      return(
          <>
              <div className="hero-text-container">
                <img src={frontLogoImage} className="main-hero-image" alt=""/>
              </div>
              <p style={{color:logOutButtonColor}}>
                <span className="emphasizedText">{playingTextHeader}</span>
                <br/>
                <span style={{color:logOutButtonColor}} dangerouslySetInnerHTML={{ __html:playingTextBody}}/>
              </p>
          </>
        )
    }

    checkIfWon(numberOfMoves){
      const matches = this.state.matches;
      let completed = true;
      for(const i in matches){
        if(!matches[i].discovered){
          completed = false
        }
      }
      if(completed){
        this.fireWinningMessage(numberOfMoves)
      }
    }

    flipCard(index){
      const guess1 = this.state.guess1;
      const guess2 = this.state.guess2;
      const matches = this.state.matches;
      let numberOfMoves = this.state.numberOfMoves;
      if(matches[index].flipped){
        return;
      }
      numberOfMoves+=1
      matches[index].flipped = true
      if(!matches[guess1]){
        this.setState({
          guess1: index,
          matches: matches
        })
      } else if(matches[guess1] && !matches[guess2]){
        this.setState({
          matches:matches,
          guess2: index
        }, ()=>{
          if(matches[guess1].id === matches[index].id){
            matches[guess1].discovered = true
            matches[index].discovered = true
            this.setState({
              matches: matches
            }, () => {
              this.checkIfWon(numberOfMoves)
            })
          }
        })
      } else {
        matches[guess1].flipped = false;
        matches[guess2].flipped = false;
        this.setState({
          matches: matches
        }, () => {
          matches[index].flipped = true;
          this.setState({
            guess1: index,
            guess2: null,
            matches: matches
          })
        })
      }
      this.setState({
        numberOfMoves: numberOfMoves
      })
    }

    restartClicked(){
      const newMatches = [];
      const enteredIds = [];
      const matches = this.state.matches;
      for(let ind in matches){
        if(enteredIds.indexOf(matches[ind].id) === -1){
          enteredIds.push(matches[ind].id);
          newMatches.push(matches[ind]);
        }
      }
      this.shuffleMatches(newMatches)
      this.setState({
        numberOfMoves: 0,
        startConfetti:false,
      })
    }

    renderPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const topLeftImage = tenantVariables.topHeaderImage || sampleAppTopImage;
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const coveringImage = tenantVariables.cardBackImage || sampleCardImage;
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const matches = this.state.matches;
      let sizeOfMatches = 100;
      if(matches && matches.length > 0 && matches.length % 3 !== 0){
        sizeOfMatches = 150;
      }
      return(
          <>
              <div className="creator-awards-logo-playscreen user">
                <img src={topLeftImage} alt="" style={{margin:0}}/>
              </div>
              <div className="spacer-md"/>
              <div className="movesCounter" style={{color: logOutButtonColor}}>
                {this.state.numberOfMoves}
              </div>
              <div className="spacer-md"/>
              {matches && matches.length > 0 &&
                <div style={{display:'flex', flexDirection:'row', flexWrap: "wrap", width:330}}>
                  {
                    matches.map(function(item, index){
                      let cardClass = classNames({
                        'flipper' : true,
                        'flipped' : item.flipped || item.discovered
                      });
                        return(
                            <div key={index} className="flip-container" style={{display:'flex', flexDirection: 'column', margin:5, width: sizeOfMatches, height: sizeOfMatches}}>
                              <div className={cardClass} style={{width: sizeOfMatches, height: sizeOfMatches}}>
                                { (!item.flipped && !item.discovered) &&
                                  <img className="front" onClick={() => this.flipCard(index)} width={sizeOfMatches} height={sizeOfMatches} src={coveringImage} alt=""/>
                                }
                                <img className="back" width={sizeOfMatches} height={sizeOfMatches} src={item.image} alt=""/>
                              </div>
                            </div>
                        )
                    }, this)
                  }
                  <div className="spacer-md"/>
                </div>
              }
              <div style={{marginTop:5, textAlign: "center"}}>
                <button className="btn btn-next" onClick={()=> this.restartClicked()} style={{backgroundColor: primaryColor || "black", color: secondaryColor || "white"}}>RESTART</button>
              </div>
              <div style={{height:"100px", width:"1px"}}/>
          </>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevState.active && !this.state.active && prevState.currentGameId === this.state.currentGameId){
        const userEmailToBase64 = btoa(this.state.userEmail);
        const gameid = this.state.currentGameId;
        base.fetch('userGameHistory/'+userEmailToBase64+`/${gameid}` , {
          context: this,
          then(data){
            if(typeof data === "object"){
              this.logUserActivity(gameid);
              this.removeReactAlert();
              this.restartClicked();
            }
          }
        })
      }
    }

    render() {
      const tenantVariables = this.props.variables || {};
      const tenantRules = this.state.tenantRules || {};
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
      let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
      let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
      let rulesPopUpText = tenantRules.rulesPopUpText;
      let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
      const link = tenantRules.rulesAndRegsLink;
      const rulesShowInApp = tenantRules.rulesShowInApp || false;
      if(rulesShowInApp) {
        if(!rulesShowInAppPopUpText && rulesPopUpText){
          rulesShowInAppPopUpText = rulesPopUpText;
        }
        if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
          rulesShowInAppPopUpHeader = rulesPopUpHeader;
        }
      }
      if(!this.state.userEmail){
        return (
            <Redirect to="/login" />
        )
      } else if(!tenantVariables.doNotCollectEmail && !validateEmail(this.state.userEmail)){
        this.logout();
      } else if(tenantVariables.doNotCollectEmail && validateEmail(this.state.userEmail)){
        this.logout();
      }
      if(!verifiedAge){
        return (
            <Redirect to={"/age_gate"} />
        )
      }
      if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading} backgroundImage={backgroundImage}/>
        )
      }
      let renderMainScreen = this.renderHoldingScreen()
      if(this.state.active === true && !this.state.ended && !this.state.locationChecking){
        renderMainScreen = this.renderPlayingScreen()
      }
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")", scrollbarWidth:"none"}}>
            {this.state.startConfetti &&
              <Confetti numberOfPieces={400} width={this.state.width} height={this.state.height} style={{zIndex: 1}}/>
            }
            <div className="flex-content-container-home">
              <div className="intro-container-home" style={{margin: "auto"}}>
                <div className="grid-wrapper">
                  <div className="flex-header-home" style={{marginTop:10}}>
                    <div style={{visibility: rulesShowInApp? "visible":"hidden"}}>
                      <button className="btn btn-logout" onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{rulesInAppButtonText}</button>
                    </div>
                    <div>
                      <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>LOG OUT</button>
                    </div>
                  </div>
                  {renderMainScreen}
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                    {rulesShowInAppPopUpHeader}
                </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>Dismiss</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
          </div>
      )
    }
}

export default Main_App;
